import React from "react";
import Action from "../components/takeAction/action";

export default function ActionPage() {
  return (
    <div>
      <Action />
    </div>
  );
}
