import React from "react";
import Home from "../components/home";

export default function HomePage() {
  return (
    <div>
      <Home />
    </div>
  );
}
