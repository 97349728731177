const Images = [
  require("../../ui/assets/photos/breakfast/b1.JPG").default,
  require("../../ui/assets/photos/breakfast/b2.JPG").default,
  require("../../ui/assets/photos/breakfast/b3.JPG").default,
  require("../../ui/assets/photos/breakfast/b4.JPG").default,
  require("../../ui/assets/photos/breakfast/b5.JPG").default,
  require("../../ui/assets/photos/breakfast/b6.JPG").default,
  require("../../ui/assets/photos/breakfast/b7.JPG").default,
  require("../../ui/assets/photos/breakfast/b8.JPG").default,
  require("../../ui/assets/photos/breakfast/b9.JPG").default,
  require("../../ui/assets/photos/breakfast/b10.JPG").default,
  require("../../ui/assets/photos/breakfast/b11.JPG").default,
  require("../../ui/assets/photos/breakfast/b12.JPG").default,
  require("../../ui/assets/photos/breakfast/b13.JPG").default,
  require("../../ui/assets/photos/breakfast/b14.JPG").default,
  require("../../ui/assets/photos/breakfast/b15.JPG").default,
  require("../../ui/assets/photos/breakfast/b16.JPG").default,
  require("../../ui/assets/photos/breakfast/b17.JPG").default,
  require("../../ui/assets/photos/breakfast/b18.JPG").default,
  require("../../ui/assets/photos/breakfast/b19.JPG").default,
  require("../../ui/assets/photos/breakfast/b20.JPG").default,
  require("../../ui/assets/photos/breakfast/b21.JPG").default,
  require("../../ui/assets/photos/breakfast/b22.JPG").default,
  require("../../ui/assets/photos/breakfast/b23.JPG").default,
  require("../../ui/assets/photos/breakfast/b24.JPG").default,
  require("../../ui/assets/photos/breakfast/b25.jpg").default,
  require("../../ui/assets/photos/breakfast/b26.jpg").default,
  require("../../ui/assets/photos/breakfast/b27.jpg").default,
  require("../../ui/assets/photos/breakfast/b28.jpg").default,
  require("../../ui/assets/photos/breakfast/b29.jpg").default,
  require("../../ui/assets/photos/breakfast/b30.jpg").default,
  require("../../ui/assets/photos/breakfast/b31.jpg").default,
  require("../../ui/assets/photos/breakfast/b32.jpg").default,
  require("../../ui/assets/photos/breakfast/b33.jpg").default,
  require("../../ui/assets/photos/breakfast/b34.jpg").default,
  require("../../ui/assets/photos/breakfast/b35.jpg").default,
  require("../../ui/assets/photos/breakfast/b36.jpg").default,
  require("../../ui/assets/photos/breakfast/b37.jpg").default,
  require("../../ui/assets/photos/breakfast/b38.jpg").default,
  require("../../ui/assets/photos/breakfast/b39.jpg").default,
  require("../../ui/assets/photos/breakfast/b39.jpg").default,
  require("../../ui/assets/photos/breakfast/b40.jpg").default,
  require("../../ui/assets/photos/misc/misc1.jpg").default,
  require("../../ui/assets/photos/misc/misc2.jpg").default,
  require("../../ui/assets/photos/misc/misc3.jpg").default,
  require("../../ui/assets/photos/misc/misc4.jpg").default,
  require("../../ui/assets/photos/misc/misc5.jpg").default,
  require("../../ui/assets/photos/misc/misc6.jpg").default,
  require("../../ui/assets/photos/misc/misc7.jpg").default,
  require("../../ui/assets/photos/misc/misc8.jpg").default,
  require("../../ui/assets/photos/misc/misc9.jpg").default,
  require("../../ui/assets/photos/misc/misc10.jpg").default,
  require("../../ui/assets/photos/misc/misc11.jpg").default,
  require("../../ui/assets/photos/misc/misc12.png").default,
  require("../../ui/assets/photos/misc/misc13.png").default,
];

export default Images;
