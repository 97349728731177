import React from "react";
import ActionDisplay from "./molecules/actionDisplay";

export default function Action() {
  return (
    <div>
      <ActionDisplay />
    </div>
  );
}
