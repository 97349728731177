import React from "react";
import Mentors from "../components/mentor/mentor";

export default function AboutPage() {
  return (
    <div>
      <Mentors />
    </div>
  );
}
